import App from './App.vue'
import Axios from './plugins/axios'
import { Auth0Plugin } from '@/auth/auth0-plugin'
import { faLink, faPowerOff, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import hljs from 'highlight.js/lib/core'
import 'highlight.js/styles/github.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import moment from 'moment'
import Rollbar from 'rollbar'
import router from '@/router'
import titleMixin from './mixins/titleMixin'
import Vue from 'vue'
import vueNumeralFilterInstaller from 'vue-numeral-filter'
import vuetify from '@/plugins/vuetify'
import '@/plugins'
import { audience, clientId, domain } from '../auth_config.json'

Vue.config.productionTip = false

Vue.filter('formatDate', function (value) {
    if (value) {
        return moment.unix(value).format('MM/DD/YYYY hh:mm')
    }
})
Vue.mixin(titleMixin)
Vue.use(vueNumeralFilterInstaller, { locale: 'en-gb' })
Vue.use(hljs.vuePlugin)
Vue.use(Auth0Plugin, {
    domain,
    clientId,
    audience,
    onRedirectCallback: (appState) => {
        router.push(
            appState && appState.targetUrl
                ? appState.targetUrl
                : window.location.pathname,
        )
    },
})
Vue.use(Axios)

Vue.prototype.$rollbar = new Rollbar({
    accessToken: process.env.VUE_APP_POST_CLIENT_ITEM_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
})

Vue.config.errorHandler = (err, vm) => {
    vm.$rollbar.error(err)
    throw err // rethrow
}

library.add(faLink, faUser, faPowerOff)

Vue.component('FontAwesomeIcon', FontAwesomeIcon)

new Vue({
    vuetify,
    el: '#app',
    router,
    render: h => h(App),
}).$mount('#app')
