<template>
  <v-theme-provider :dark="dark">
    <v-row>
      <template v-for="({ icon, text, title: t }, i) in business">
        <v-col
          :key="i"
          cols="4"
        >
          <v-row>
            <v-col
              cols="10"
            >
              <base-avatar-card
                :icon="icon"
                :outlined="false"
                :title="!dense ? t : undefined"
                color="transparent"
                space="0"
              >
                <!-- Do not use v-html for user -->
                <!-- provided values -->
                <div v-html="text" />
              </base-avatar-card>
            </v-col>
            <v-col
              v-if="i + 1 !== business.length"
              class="text-center hidden-sm-and-down"
              cols="2"
            >
              <v-divider
                :key="`divider-${i}`"
                class="my-2"
                vertical
              />
            </v-col>
          </v-row>
        </v-col>
      </template>
    </v-row>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'BaseBusinessContactHorizontal',

    props: {
      dark: Boolean,
      dense: Boolean,
      title: String,
    },

    data: () => ({
      business: [
        {
          icon: 'mdi-email',
          title: 'Email',
          text: 'john.fonte@practicemakes.io',
        },
        {
          icon: 'mdi-cellphone',
          title: 'Phone',
          text: '(734) 821-6785',
        },
        {
          icon: 'mdi-map-marker-outline',
          title: 'Address',
          text: '3200 Tall Timber Dr<br>Orlando, FL 32812',
        },
      ],
    }),
  }
</script>
